<template>
  <div
    class="flex min-h-screen w-full flex-col justify-between pt-nav-sm lg:pt-nav-lg"
  >
    <MainHeader class="fixed top-0 z-top w-full lg:top-6" />

    <main class="flex-1">
      <slot />
    </main>

    <AppFooter />

    <BaseBreaky />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
</script>
